import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UploadLecture from "./components/UploadLecture";
import LectureList from "./components/LectureList";
import Register from "./components/Register";
import AdminDashboard from "./components/AdminDashboard";
import AdminLogin from "./components/AdminLogin";
import ProtectedAdminRoute from "./components/ProtectedAdminRoute";
import Layout from "./components/Layout";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import MagicLoginForm from "./components/MagicLoginForm";
import MagicLoginCallback from "./components/MagicLoginCallback";
import MyLectures from './components/MyLectures';
import EditLecture from './components/EditLecture';
import EditProfile from './components/EditProfile';
import EmailConfirmation from './components/EmailConfirmation';

import "./App.css";

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 3600000); // 1 hour in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Layout>
        <div>
          <Routes>
            <Route path="/upload" element={<UploadLecture />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/admin"
              element={
                <ProtectedAdminRoute>
                  <AdminDashboard />
                </ProtectedAdminRoute>
              }
            />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/magic-login" element={<MagicLoginForm />} />
            <Route
              path="/magic-login/:token"
              element={<MagicLoginCallback />}
            />
            <Route path="/my-lectures" element={<MyLectures />} />
            <Route path="/edit-lecture/:id" element={<EditLecture />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/confirm/:token" element={<EmailConfirmation/>} />
            <Route path="/" element={<LectureList />} />
          </Routes>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
