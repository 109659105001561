import React, { useState, useEffect } from "react";
import axios from "axios";
import Joyride from "react-joyride";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import PreviewIcon from "@mui/icons-material/Preview";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AssignmentCalendar from "./AssignmentCalendar";

const LectureList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const [lectures, setLectures] = useState([]);
  const [missingSubjects, setMissingSubjects] = useState([]);
  const [selectedWeekLectures, setSelectedWeekLectures] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState("October"); // Default month
  const [assignments, setAssignments] = useState([]);
  const [showPastAssignments, setShowPastAssignments] = useState(false);
  const monthNames = ["October", "November", "December"];

  const fetchLectures = async (month) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/lectures/m/${month}`
      );
      setLectures(response.data);
    } catch (error) {
      setError("Error fetching lectures. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchAssignments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/assignments`
      );
      setAssignments(response.data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  useEffect(() => {
    fetchLectures(currentMonth); // Fetch lectures for the default month on component mount
  }, [currentMonth]);

  useEffect(() => {
    fetchAssignments();
  }, []);
  const handleWeekClick = (week) => {
    const filteredLectures = lectures.filter(
      (lecture) => lecture.week === week
    );
    setSelectedWeekLectures(filteredLectures);
    setSelectedWeek(week);

    // Check for missing subjects
    const missingSubjects = specificSubjects.filter(
      (subject) =>
        !filteredLectures.some((lecture) => lecture.subject.includes(subject))
    );
    setMissingSubjects(missingSubjects);
  };

  const handlePreviousMonth = () => {
    const currentIndex = monthNames.indexOf(currentMonth);
    if (currentIndex > 0) {
      setCurrentMonth(monthNames[currentIndex - 1]);
    }
  };

  const handleNextMonth = () => {
    const currentIndex = monthNames.indexOf(currentMonth);
    if (currentIndex < monthNames.length - 1) {
      setCurrentMonth(monthNames[currentIndex + 1]);
    }
  };
  const specificSubjects = [
    "Arabic",
    "Comparative Literature",
    "Conversation Skills",
    "Essay",
    "Literary Criticism",
    "Sociolinguistics",
    "Stylistics",
    "Translation",
  ];

  const weekDates = {
    October: {
      "Week 1": "1-2/10/2024",
      "Week 2": "6-10/10/2024",
      "Week 3": "13-17/10/2024",
      "Week 4": "20-24/10/2024",
      "Week 5": "27-30/10/2024",
    },
    November: {
      "Week 1": "3-7/11/2024",
      "Week 2": "10-14/11/2024",
      "Week 3": "17-21/11/2024",
      "Week 4": "24-29/11/2024",
    },
    December: {
      "Week 1": "1-5/12/2024",
      "Week 2": "8-12/12/2024",
      "Week 3": "15-19/12/2024",
      "Week 4": "22-26/12/2024",
      "Week 5": "29-31/12/2024",
    },
  };
  const [tourRunning, setTourRunning] = useState(false);

  const tourSteps = [
    {
      target: ".assignment-section",
      content:
        "Welcome to the new Assignments section! Here you can view all your assignments.",
      disableBeacon: true,
    },
    {
      target: ".week-container",
      content: "Here, you can see lectures",
    },
    {
      target: ".telegram-notification",
      content:
        "Do not forget to join the telegram channel if ever the website went down.",
    },
  ];
  useEffect(() => {
    // Check if the tour has already been shown
    const tourShown = localStorage.getItem("tourShown");
    if (!tourShown) {
      // Start the tour if it hasn't been shown
      startTour();
    }
  }, []);
  const startTour = () => {
    // Logic to start the tour
    setTourRunning(true);
    // Set the flag in localStorage to indicate the tour has been shown
    localStorage.setItem("tourShown", "true");
  };

  // // test pop up alert
  // const [openModal, setOpenModal] = useState(false);
  // useEffect(() => {
  //   // For testing, we'll show the modal every time
  //   setOpenModal(true);

  //   // For production, you might want to use localStorage to show it only once
  //   // const modalShown = localStorage.getItem("modalShown");
  //   // if (!modalShown) {
  //   //   setOpenModal(true);
  //   //   localStorage.setItem("modalShown", "true");
  //   // }
  // }, []);

  // // test pop up alert

  // test noti
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    const notificationShown = localStorage.getItem("notificationShown");
    if (!notificationShown) {
      setShowNotification(true);
      localStorage.setItem("notificationShown", "true");
    }
  }, []);
  // test noti

  const [expanded, setExpanded] = useState({});
  const toggleExpand = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const renderDescription = (description, id) => {
    if (description.length <= 110) {
      return description;
    }

    if (expanded[id]) {
      return (
        <>
          {description}
          <Button onClick={() => toggleExpand(id)}>Read Less</Button>
        </>
      );
    }

    return (
      <>
        {description.substring(0, 110)}...
        <Button onClick={() => toggleExpand(id)}>Read More</Button>
      </>
    );
  };

  // test counter

  const handleDownloadClick = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/counter`, {
      type: "download",
    });
  };

  const handlePreviewClick = () => {
    console.log("Preview button clicked");
    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/counter`, {
      type: "preview",
    });
  };

  const handlePreviewButtonClick = (e) => {
    handlePreviewClick();
    // Allow the event to propagate to the EmbedPDF library
  };

  // test counter
  return (
    <Box
      sx={{
        padding: isMobile ? 1 : isTab ? 2 : 3,
        backgroundColor: "#282c34",
        color: "#fff",
        width: isMobile ? "100%" : isTab ? "90%" : "95%",
        margin: "0 auto",
        marginLeft: isMobile ? -1 : "reset",
        marginRight: isMobile ? 1 : "reset",
      }}
    >
      {/* test noti  */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showNotification}
        onClose={() => setShowNotification(false)}
        message="New Feature: Assignments Section"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setShowNotification(false)}
          >
            Close
          </Button>
        }
      />
      {/* test noti  */}
      {/* test pop up alert */}
      {/* <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            New Feature: Assignments Section
          </Typography>
          <Typography variant="body1">
            We have added a new Assignments section where you can view all your
            assignments.
          </Typography>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog> */}
      {/* test pop up alert */}
      <Joyride steps={tourSteps} run={tourRunning} continuous />
      <Typography
        variant={isMobile ? "h5" : "h4"}
        gutterBottom
        align="center"
        color="primary"
      >
        Lecture List
      </Typography>

      <Alert
        severity="info"
        className="telegram-notification"
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#282c34",
          color: "#fff",
          flexDirection: isMobile ? "column" : "row",
          padding: isMobile ? 1 : 2,
        }}
      >
        <Typography
          variant={isMobile ? "body1" : "h6"}
          sx={{
            fontWeight: "bold",
            fontSize: isMobile ? "1rem" : "1.25rem",
            marginBottom: isMobile ? 1 : 0,
          }}
        >
          Check our telegram for instant updates and notifications on new
          lectures <Box component="span" sx={{ marginLeft: "5px" }}></Box>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            (window.location.href = "https://t.me/+_8UJijSnxtU3ZDFk")
          }
          startIcon={
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
              alt="Telegram Logo"
              style={{ width: "20px", height: "20px" }}
            />
          }
          sx={{ marginTop: isMobile ? 1 : 0 }}
        >
          Click here
        </Button>
      </Alert>

      {/* Month Navigation */}
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 2 }}
      >
        <Button
          variant="contained"
          onClick={handlePreviousMonth}
          disabled={currentMonth === "October"}
          sx={{ marginBottom: isMobile ? 1 : 0 }}
        >
          Previous Month
        </Button>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          color="primary"
          sx={{ marginBottom: isMobile ? 1 : 0 }}
        >
          {currentMonth}
        </Typography>
        <Button
          variant="contained"
          onClick={handleNextMonth}
          disabled={currentMonth === "November"}
        >
          Next Month
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box className="week-container" display="flex" flexWrap="wrap" gap={2}>
          {Object.entries(weekDates[currentMonth])
            .filter(
              ([week, date]) =>
                lectures.filter((lecture) => lecture.week === week).length > 0
            )
            .map(([week, date], idx) => (
              <Box
                key={idx}
                sx={{
                  border: "1px solid #666",
                  borderRadius: "4px",
                  padding: isMobile ? 1 : 2,
                  cursor: "pointer",
                  backgroundColor: "#3a3d42",
                  "&:hover": { backgroundColor: "#555" },
                  flex: isMobile ? "1 1 100%" : isTab ? "1 1 45%" : "1 1 30%",
                }}
                onClick={() => handleWeekClick(week)}
              >
                <Typography
                  variant={isMobile ? "body1" : "h6"}
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  {week}
                </Typography>
                <Typography
                  sx={{
                    color: "#bbb",
                    textAlign: "center",
                  }}
                >
                  {date}
                </Typography>
                <Typography variant="body2" sx={{ color: "#ddd" }}>
                  Lectures included:{" "}
                  {lectures
                    .filter((lecture) => lecture.week === week)
                    .filter(
                      (lecture) =>
                        !lecture.subject.toLowerCase().includes("canceled")
                    )
                    .filter(
                      (lecture) =>
                        !lecture.subject.toLowerCase().includes("uploaded")
                    )
                    .map((lecture) => lecture.subject)
                    .join(", ")}
                </Typography>
              </Box>
            ))}
        </Box>
      )}

      {/* Dialog for Week Lectures */}
      <Dialog
        open={Boolean(selectedWeek)}
        onClose={() => setSelectedWeek(null)}
        fullWidth
        maxWidth={isMobile ? "xs" : isTab ? "sm" : "md"}
      >
        <DialogContent
          sx={{
            backgroundColor: "#282c34",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setSelectedWeek(null)}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            gutterBottom
            align="center"
            color="primary"
          >
            Lectures for {selectedWeek}
          </Typography>

          {missingSubjects.length > 0 && (
            <Alert
              severity="info"
              sx={{
                order: 1,
                alignItems: "center",
                backgroundColor: "#444444",
                color: "#fff",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
                {missingSubjects.join(", ")},{" "}
                {missingSubjects.length !== 1 ? "are" : "is"} Missing.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: "auto",
                  color: "#fff",
                  fontWeight: "lighter",
                }}
              >
                This means the above lecture
                {missingSubjects.length !== 1 ? `s are` : ` is`} canceled,
                missing or not uploaded yet.
              </Typography>
            </Alert>
          )}

          {selectedWeekLectures.length > 0 ? (
            selectedWeekLectures.map((lecture) => (
              <Box
                key={lecture._id}
                sx={
                  lecture.subject.toLowerCase().includes("canceled") ||
                  lecture.subject.toLowerCase().includes("uploaded")
                    ? {
                        order: 1,
                        marginBottom: 2,
                        padding: isMobile ? 1 : 2,
                      }
                    : {
                        order: 0,
                        marginBottom: 2,
                        padding: isMobile ? 1 : 2,
                        border: "1px solid #666",
                        borderRadius: "4px",
                        backgroundColor: "#3a3d42",
                        "&:hover": { backgroundColor: "#555" },
                      }
                }
              >
                {lecture.subject.toLowerCase().includes("canceled") ||
                lecture.subject.toLowerCase().includes("uploaded") ? (
                  <Alert
                    severity="info"
                    sx={{
                      order: 1,
                      alignItems: "center",
                      backgroundColor: "#444444",
                      color: "#fff",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
                      {lecture.subject}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginLeft: "auto",
                        color: "#fff",
                        fontWeight: "lighter",
                      }}
                    >
                      This means the above lecture/s is/are canceled or not
                      uploaded yet.
                    </Typography>
                  </Alert>
                ) : (
                  <>
                    <Typography
                      variant={isMobile ? "body1" : "h6"}
                      sx={{
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      {lecture.subject}
                    </Typography>
                    <Typography sx={{ color: "#bbb" }}>
                      Author: {lecture.author}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                        marginTop: 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        href={`${lecture.fileUrl}`}
                        download
                        target="_blank"
                        sx={{ flex: isMobile ? "1 1 auto" : "none" }}
                        onClick={handleDownloadClick}
                      >
                        Download
                      </Button>
                      <div onClick={handlePreviewButtonClick}>
                        <EmbedPDF companyIdentifier="r55f6z52">
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<PreviewIcon />}
                            href={`${lecture.fileUrl}`}
                            target="_blank"
                            sx={{ flex: isMobile ? "1 1 auto" : "none" }}
                          >
                            Preview
                          </Button>
                        </EmbedPDF>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            ))
          ) : (
            <Typography color="error">
              No lectures available for this week.
            </Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Assignments Section */}
      <Box
        className="assignment-section"
        sx={{ marginTop: 4, borderTop: "solid #696969" }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          gutterBottom
          align="center"
          color="primary"
          marginTop="20px"
        >
          Assignments
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {assignments
            .filter((assignment) => !assignment.isPast)
            .map((assignment, idx) => (
              <Box
                key={idx}
                sx={{
                  border: "1px solid #666",
                  borderRadius: "4px",
                  padding: isMobile ? 1 : 2,
                  backgroundColor: "#3a3d42",
                  "&:hover": { backgroundColor: "#555" },
                  flex: isMobile ? "1 1 100%" : isTab ? "1 1 45%" : "1 1 30%",
                }}
              >
                <Typography
                  variant={isMobile ? "body1" : "h6"}
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  {assignment.title}
                </Typography>
                <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                  Subject: {assignment.subject}
                </Typography>
                <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                  Deadline: {assignment.dueDate}
                </Typography>
                <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                  Day: {assignment.day}
                </Typography>
                {assignment.place ? (
                  <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                    Where?: {assignment.place}
                  </Typography>
                ) : (
                  ``
                )}
                <Typography variant="body2" sx={{ color: "#ddd" }}>
                  {renderDescription(assignment.description, assignment._id)}
                </Typography>
              </Box>
            ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowPastAssignments(!showPastAssignments)}
          sx={{ marginTop: 2 }}
        >
          {showPastAssignments
            ? "Hide Past Assignments"
            : "Show Past Assignments"}
        </Button>
        {showPastAssignments && (
          <Box display="flex" flexWrap="wrap" gap={2} sx={{ marginTop: 2 }}>
            {assignments
              .filter((assignment) => assignment.isPast)
              .map((assignment, idx) => (
                <Box
                  key={idx}
                  sx={{
                    border: "1px solid #666",
                    borderRadius: "4px",
                    padding: isMobile ? 1 : 2,
                    backgroundColor: "#3a3d42",
                    "&:hover": { backgroundColor: "#555" },
                    flex: isMobile ? "1 1 100%" : isTab ? "1 1 45%" : "1 1 30%",
                  }}
                >
                  <Typography
                    variant={isMobile ? "body1" : "h6"}
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {assignment.title}
                  </Typography>
                  <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                    Subject: {assignment.subject}
                  </Typography>
                  <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                    Due Date: {assignment.dueDate}
                  </Typography>
                  <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                    Day: {assignment.day}
                  </Typography>
                  {assignment.place ? (
                    <Typography sx={{ color: "#bbb", textAlign: "center" }}>
                      Where?: {assignment.place}
                    </Typography>
                  ) : (
                    ``
                  )}
                  <Typography variant="body2" sx={{ color: "#ddd" }}>
                    {renderDescription(assignment.description, assignment._id)}
                  </Typography>
                </Box>
              ))}
          </Box>
        )}
        <AssignmentCalendar />
      </Box>
    </Box>
  );
};

export default LectureList;
