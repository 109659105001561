import React from "react";
import { Box, Typography, Link, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="footer"
      sx={{
        padding: isMobile ? 1 : 2,
        textAlign: "center",
        backgroundColor: "#333",
        color: "#ffffff",
        marginTop: "auto",
        zIndex: 1000,
        position: "relative",
      }}
    >
<Typography
  variant="body2"
  sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
>
  Made with ❤️ by Islam
</Typography>
<Typography
  variant="body2"
  sx={{ fontSize: isMobile ? "0.8rem" : "1rem", marginTop: 1 }}
>
  For further assistant message me on <Link href="https://api.whatsapp.com/send?phone=201553526394" style={{ color: "#4CAF50" }}>Whatsapp, Click here</Link>
</Typography>
  
      <Typography
        variant="body2"
        sx={{ fontSize: isMobile ? "0.8rem" : "1rem", marginTop: 1 }}
      >
        <a href="http://status.lectures.iasys.me/" style={{ color: "#ffffff",  }}>
          Click to check System Status
        </a>
      </Typography>
    </Box>
  );
};

export default Footer;
