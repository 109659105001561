import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import calendar styles
import axios from "axios";
import { Box, Typography, List, ListItem } from "@mui/material";

const AssignmentCalendar = () => {
  const [assignments, setAssignments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [assignmentsByDate, setAssignmentsByDate] = useState([]);

  // Fetch assignments on component mount
  useEffect(() => {
    fetchAssignments();
  }, []);
  const fetchAssignments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/assignments`
      );
      setAssignments(response.data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };
  const onDateChange = (date) => {
    setSelectedDate(date);
    const assignmentsForDate = getAssignmentsForDate(date);
    setAssignmentsByDate(assignmentsForDate);
  };
  const getAssignmentsForDate = (date) => {
    return assignments.filter((assignment) => {
      const assignmentDate = new Date(assignment.dueDate);
      return assignmentDate.toDateString() === date.toDateString();
    });
  };
  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const assignmentsForDate = getAssignmentsForDate(date);
      if (assignmentsForDate.length > 0) {
        return (
          <div
            style={{
              backgroundColor: "#1976d2",
              color: "white",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              textAlign: "center",
              lineHeight: "20px",
              margin: "0 auto",
            }}
          >
            {assignmentsForDate.length}
          </div>
        );
      }
    }
    return null;
  };
  return (
    <Box sx={{ padding: 2, backgroundColor: "#282c34", color: "#fff" }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        Assignment Calendar
      </Typography>
      <Calendar
        onChange={onDateChange}
        value={selectedDate}
        tileContent={tileContent}
        calendarType="islamic"
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h5" color="primary">
          Assignments on {selectedDate.toDateString()}
        </Typography>
        {assignmentsByDate.length > 0 ? (
          <List>
            {assignmentsByDate.map((assignment) => (
              <ListItem key={assignment._id} sx={{ display: "block" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#fff" }}
                >
                  {assignment.title}
                </Typography>
                <Typography sx={{ color: "#bbb" }}>
                  Subject: {assignment.subject}
                </Typography>
                <Typography sx={{ color: "#ddd" }}>
                  {assignment.description}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No assignments due on this date.</Typography>
        )}
      </Box>
    </Box>
  );
};
export default AssignmentCalendar;
