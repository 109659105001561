import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutButton from "./LogoutButton";
import useAuth from "./useAuth";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const buttonStyle = {
  padding: "0.5rem 1rem",
  color: "#ffffff",
};

const Header = () => {
  const { isLoggedIn, isAdmin } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const isMobile = useMediaQuery('(max-width:780px)');
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };

  const menuItems = [

    { text: "Lectures", link: "/" },
    { text: "Post a Lecture", link: "/upload" },
    ...(isLoggedIn
      ? [
        { text: "My lectures", link: "/my-lectures" },
        { text: "Edit my profile", link: "/edit-profile" },
        ...(isAdmin ? [{ text: "Admin Dashboard", link: "/admin" }] : []),
        { text: "Logout", action: handleLogout },
        
      ]
      : [
          { text: "Login", link: "/login" },
          { text: "Sign Up", link: "/register" },
        ]),
  ];

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#282c34", zIndex: 1000 }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Lectures
          </Link>
        </Typography>

        {isMobile ? (
          <>
            {isLoggedIn && (
              <LogoutButton
                showWelcome={true}
                showUsername={true}
                showLogoutButton={false}
                style={buttonStyle}
              />
            )}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              sx={{ opacity: 0.9 }}
            >
              <Box
                sx={{ width: 250, backgroundColor: "#333", height: "100%" }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem
                      button
                      component={item.action ? undefined : Link}
                      to={item.link}
                      key={index}
                      onClick={item.action}
                      sx={{ color: "#fff", backgroundColor: "#444" }}
                    >
                      <ListItemText
                        primary={item.text}
                        sx={{ color: "#fff" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            {menuItems.map((item, index) =>
              item.action ? (
                <Button color="inherit" onClick={item.action} key={index}>
                  {item.text}
                </Button>
              ) : (
                <Button
                  color="inherit"
                  component={Link}
                  to={item.link}
                  key={index}
                >
                  {item.text}
                </Button>
              )
            )}
            {isLoggedIn && (
              <LogoutButton
                showWelcome={true}
                showUsername={true}
                showLogoutButton={false}
                style={buttonStyle}
              />
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
