import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm } from "react-hook-form";

const EditLecture = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const maxFileSize = 10 * 1024 * 1024; // 10MB file size limit
  const { id } = useParams(); // get lecture ID from URL
  const [lecture, setLecture] = useState({
    subject: '',
    author: '',
    month: '',
    week: '',
  });
  const [originalLecture, setOriginalLecture] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); 
  const [changeFile, setChangeFile] = useState(false); 
  const navigate = useNavigate();
  
  // Get the month from the URL
  const [searchParams] = useSearchParams();
  const month = searchParams.get('month');
  useEffect(() => {
    const fetchLecture = async () => {
      try {
        const token = localStorage.getItem('token');
        // Fetch lecture data using month from URL
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/lectures/${id}?month=${month}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLecture(response.data);
        setOriginalLecture(response.data);
      } catch (err) {
        setError('Failed to load lecture data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLecture();
  }, [id, month]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLecture((prevLecture) => {
      const updatedLecture = { ...prevLecture, [name]: value };
      if (
        (name === 'subject' && value !== originalLecture.subject) ||
        (name === 'month' && value !== originalLecture.month) ||
        (name === 'week' && value !== originalLecture.week)
      ) {
        setChangeFile(true);
      } else if (
        (name === 'subject' && value === originalLecture.subject) &&
        (name === 'month' && value === originalLecture.month) &&
        (name === 'week' && value === originalLecture.week)
      ) {
        setChangeFile(false);
      }
      return updatedLecture;
    });
  };

  const onSubmit = async (data) => {
    setOpenDialog(true); // Open the dialog to confirm submission
  };

  const confirmUpdate = async (data) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('subject', lecture.subject);
      formData.append('author', lecture.author);
      formData.append('month', lecture.month);
      formData.append('week', lecture.week);
      if (changeFile) {
        formData.append('file', data.file[0]);
      }

      // Update lecture data using month from URL
      await axios.put(`${process.env.REACT_APP_API_URL}/api/lectures/${id}?month=${month}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess(true);
      setTimeout(() => navigate('/my-lectures'), 1500);
    } catch (err) {
      setError('Failed to update lecture.');
    } finally {
      setOpenDialog(false); // Close the dialog after submission
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Box sx={{ padding: 2, backgroundColor: "#282c34", color: "#fff", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Edit Lecture
      </Typography>
      {success && <Alert severity="success">Lecture updated successfully!</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          select
          label="Subject"
          name="subject"
          value={lecture.subject}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Subject</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
          <MenuItem value="Comparative Literature">Comparative Literature</MenuItem>
          <MenuItem value="Conversation Skills">Conversation Skills</MenuItem>
          <MenuItem value="Essay">Essay</MenuItem>
          <MenuItem value="Literary Criticism">Literary Criticism</MenuItem>
          <MenuItem value="Sociolinguistics">Sociolinguistics</MenuItem>
          <MenuItem value="Stylistics ">Stylistics </MenuItem>
          <MenuItem value="Translation">Translation</MenuItem>
        </TextField>
        <TextField
          label="Author"
          name="author"
          value={lecture.author}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        />
        <TextField
          fullWidth
          select
          value={lecture.month}
          label="Month"
          name="month"
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Month</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          {/* <MenuItem value="December">December</MenuItem> */}
        </TextField>
        <TextField
          fullWidth
          select
          label="Week"
          name="week"
          value={lecture.week}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Week</MenuItem>
          <MenuItem value="Week 1">Week 1</MenuItem>
          <MenuItem value="Week 2">Week 2</MenuItem>
          <MenuItem value="Week 3">Week 3</MenuItem>
          <MenuItem value="Week 4">Week 4</MenuItem>
          <MenuItem value="Week 5">Week 5</MenuItem>
        </TextField>
        <FormControlLabel
          control={
            <Checkbox
              checked={changeFile}
              onChange={(e) => setChangeFile(e.target.checked)}
              color="primary"
              disabled={
                (lecture.subject !== '' && lecture.subject !== originalLecture.subject) ||
                (lecture.month !== '' && lecture.month !== originalLecture.month) ||
                (lecture.week !== '' && lecture.week !== originalLecture.week)
              }
            />
          }
          label="Change Lecture File"
        />
        {changeFile && (
         <Box>
          {(lecture.subject !== '' && lecture.subject !== originalLecture.subject) ||
          (lecture.month !== '' && lecture.month !== originalLecture.month) ||
          (lecture.week !== '' && lecture.week !== originalLecture.week) ? 
          <Typography variant="body2" sx={{ color: "red" }}>
          Please note that if you changed one of the Month, week or subject. you must upload the lecture file again or delete the lecture and upload again </Typography> : null}
          <TextField
            fullWidth
            type="file"
            variant="outlined"
            {...register("file", {
              required: "File is required",
              validate: {
                acceptedFormats: (files) =>
                  files[0]?.type === "application/pdf" ||
                  "Only PDF files are allowed",
                fileSizeLimit: (files) =>
                  files[0]?.size <= maxFileSize ||
                  `File size exceeds the 10MB limit`,
              },
            })}
            error={!!errors.file}
            helperText={
              errors.file?.message || "Max file size: 10MB. Accepted: PDF only."
            }
            margin="normal"
            InputLabelProps={{ style: { color: "#fff" } }}
            InputProps={{ style: { color: "#fff" } }}

          />
  </Box>
        )}
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Update Lecture
        </Button>
      </form>
      {error && <Alert severity="error">{error}</Alert>}

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(confirmUpdate)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditLecture;
