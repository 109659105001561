import React, { useState, useEffect } from "react";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Preview";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [unapprovedUsers, setUnapprovedUsers] = useState([]);
  const [unapprovedLectures, setUnapprovedLectures] = useState([]);
  const [allLectures, setAllLectures] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [lectureToDelete, setLectureToDelete] = useState(null);
  const [selectedSection, setSelectedSection] = useState("approveLectures");
  const [selectedMonth, setSelectedMonth] = useState("October"); // selected month
  const [availableMonths] = useState(["October", "November", "December"]); // Available months
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUnapprovedData = async () => {
      setLoading(true);
      try {
        const [usersResponse, lecturesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/auth/unapproved`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(
            `${process.env.REACT_APP_API_URL}/api/lectures/a/approved/?month=${selectedMonth}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
        ]);
        setUnapprovedUsers(usersResponse.data);
        setUnapprovedLectures(lecturesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    const fetchAllLectures = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lectures?month=${selectedMonth}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAllLectures(response.data);
      } catch (error) {
        console.error("Error fetching all lectures:", error);
        setError("Failed to fetch lectures.");
      } finally {
        setLoading(false);
      }
    };

    fetchUnapprovedData();
    fetchAllLectures();
  }, [token, selectedMonth]); // re-fetch lectures when the selected month changes

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "lecture.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const approveUser = async (userId) => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/approve/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUnapprovedUsers(unapprovedUsers.filter((user) => user._id !== userId));
      setSuccessMessage("User approved successfully!");
    } catch (error) {
      console.error("Error approving user:", error);
      setError("Failed to approve user.");
    } finally {
      setLoading(false);
    }
  };

  const approveLecture = async (lectureId) => {
    setLoading(true);
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/lectures/approve/${lectureId}?month=${selectedMonth}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUnapprovedLectures(
        unapprovedLectures.filter((lecture) => lecture._id !== lectureId)
      );
      setSuccessMessage("Lecture approved successfully!");
    } catch (error) {
      console.error("Error approving lecture:", error);
      setError("Failed to approve lecture.");
    } finally {
      setLoading(false);
    }
  };

  const deleteLecture = async () => {
    setLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/lectures/${lectureToDelete}?month=${selectedMonth}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAllLectures(allLectures.filter((lecture) => lecture._id !== lectureToDelete));
      setSuccessMessage("Lecture deleted successfully!");
      setOpenDialog(false);
      setLectureToDelete(null);
    } catch (error) {
      console.error("Error deleting lecture:", error);
      setError("Failed to delete lecture.");
    } finally {
      setLoading(false);
    }
  };

  const renderApproveLectures = () => (
    <>
      <Typography variant="h5" color="#fff">Unapproved Lectures</Typography>
      <Select
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        sx={{ marginBottom: "20px", color: "#fff", backgroundColor: "#444" }}
      >
        {availableMonths.map((month) => (
          <MenuItem key={month} value={month}>{month}</MenuItem>
        ))}
      </Select>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {unapprovedLectures.map((lecture) => (
            <ListItem
              key={lecture._id}
              divider
              sx={{ "&:hover": { backgroundColor: "#444" } }}
            >
              <ListItemText
                primary={`${lecture.subject} by ${lecture.author}`}
                sx={{ color: "#fff" }}
              />
              <Tooltip title="Approve this lecture" arrow>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => approveLecture(lecture._id)}
                  sx={{
                    "&:hover": { backgroundColor: "#4CAF50" },
                    marginRight: "10px",
                    borderRadius: "8px",
                  }}
                >
                  Approve
                </Button>
              </Tooltip>
              <Tooltip title="Download lecture file" arrow>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDownload(lecture.fileUrl)}
                  sx={{ borderRadius: "8px" }}
                >
                  Download
                </Button>
                <EmbedPDF companyIdentifier="r55f6z52">
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<PreviewIcon />}
                        href={lecture.fileUrl}
                        target="_blank"
                        sx={{ flex: isMobile ? "1 1 auto" : "none" }}
                      >
                        Preview
                      </Button>
                      </EmbedPDF>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  const renderApproveUsers = () => (
    <>
      <Typography variant="h5" color="#fff">Unapproved Users</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {unapprovedUsers.map((user) => (
            <ListItem
              key={user._id}
              divider
              sx={{ "&:hover": { backgroundColor: "#444" } }}
            >
              <ListItemText primary={`${user.name} (${user.email})`} sx={{ color: "#fff" }} />
              <Tooltip title="Approve this user" arrow>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => approveUser(user._id)}
                  sx={{
                    "&:hover": { backgroundColor: "#4CAF50" },
                    borderRadius: "8px",
                  }}
                >
                  Approve
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  const renderEditLectures = () => (
    <>
      <Typography variant="h5" color="#fff">All Lectures</Typography>
      <Select
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        sx={{ marginBottom: "20px", color: "#fff", backgroundColor: "#444" }}
      >
        {availableMonths.map((month) => (
          <MenuItem key={month} value={month}>{month}</MenuItem>
        ))}
      </Select>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {allLectures.map((lecture) => (
            <ListItem 
              key={lecture._id}
              divider
              sx={{ "&:hover": { backgroundColor: "#444"} }}
            >
              <ListItemText 
                primary={`${lecture.subject} by ${lecture.author}`}
                sx={{ color: "#fff" }}
              />
              <Tooltip title="Edit this lecture" arrow>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(`/edit-lecture/${lecture._id}?month=${selectedMonth}`)}
                  sx={{ marginRight: "10px", borderRadius: "8px" }}
                >
                  Edit
                </Button>
              </Tooltip>
              <Tooltip title="Delete this lecture" arrow>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setOpenDialog(true);
                    setLectureToDelete(lecture._id);
                  }}
                  sx={{ borderRadius: "8px" }}
                >
                  Delete
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  return (
    <Box sx={{ padding: 3, backgroundColor: "#222", height: "100vh" }}>
      {error && <Alert severity="error">{error}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <Typography variant="h4" color="#fff" sx={{ marginBottom: "20px" }}>Admin Dashboard</Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedSection("approveLectures")}
          sx={{ marginRight: "10px" }}
        >
          Approve Lectures
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedSection("approveUsers")}
          sx={{ marginRight: "10px" }}
        >
          Approve Users
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedSection("editLectures")}
        >
          Edit Lectures
        </Button>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        {selectedSection === "approveLectures" && renderApproveLectures()}
        {selectedSection === "approveUsers" && renderApproveUsers()}
        {selectedSection === "editLectures" && renderEditLectures()}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Delete Lecture</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={deleteLecture} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDashboard;
