import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EmailConfirmation = () => {
  const { token } = useParams();
  const [message, setMessage] = useState('Confirming your email...');

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/confirm/${token}`);
      setMessage(response.data);
      } catch (error) {
        setMessage('An error occurred during confirmation. Please try again.');
      }
    };

    confirmEmail();
  }, [token]);

  return (
    <div>
      <h1>{message}</h1>
    </div>
  );
};

export default EmailConfirmation;