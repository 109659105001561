// components/MagicLoginForm.js
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const MagicLoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/magic-link`,
        { email: data.email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response?.data?.error || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: "400px",
        margin: "0 auto",
        backgroundColor: "#333",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom color="#fff">
        Magic Link Login
      </Typography>
      <TextField
        fullWidth
        label="Email"
        {...register("email")}
        error={!!errors.email}
        helperText={errors.email?.message}
        margin="normal"
        InputLabelProps={{ style: { color: "#fff" } }}
        InputProps={{ style: { color: "#fff" } }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        sx={{ marginTop: "16px", backgroundColor: "#555" }}
      >
        {loading ? <CircularProgress size={24} /> : "Send Login Link"}
      </Button>
      {error && (
        <Typography color="error" sx={{ marginTop: "16px" }}>
          {error}
        </Typography>
      )}
      {message && (
        <Typography color="primary" sx={{ marginTop: "16px" }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default MagicLoginForm;
