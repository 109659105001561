/* // components/LogoutButton.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the username from the token or localStorage (assuming it's stored in the token payload)
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUsername(decodedToken.username);
    }
  }, []);

  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem("token");
    navigate("/login"); // Redirect to login page
  };
  const token = localStorage.getItem("token");
  if (!token)
    return (
      <div>
        <span>Welcome, not logged in</span>
      </div>
    );
  if (token)
    return (
      <div>
        <span>Welcome, {username}</span>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
};

export default LogoutButton;
*/

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
const LogoutButton = ({
  style,
  className,
  showWelcome = true,
  showUsername = true,
  showLogoutButton = true,
}) => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUsername(decodedToken.username);

      // Check expiration
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("token"); // Clear expired token
        navigate("/login"); // Redirect to login page
      }
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    navigate(0);
  };

  if (!localStorage.getItem("token")) {
    return <div></div>;
  }

  return (
    <div className={className}>
      <div style={style}>
        {!localStorage.getItem("token") && <span>Welcome, not logged in</span>}
        {localStorage.getItem("token") && (
          <>
            {showWelcome && (
              <span>Welcome{showUsername && `, ${username}`}</span>
            )}
            {showLogoutButton && <Button onClick={handleLogout}>Logout</Button>}
          </>
        )}
      </div>
    </div>
  );
};

export default LogoutButton;
