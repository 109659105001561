import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Box,
  Tooltip,
} from "@mui/material";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const AdminLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          email: data.email,
          password: data.password,
        }
      );

      const token = response.data.token;
      localStorage.setItem("token", token);

      const adminResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/check-admin`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      navigate(adminResponse.data.isAdmin ? "/admin" : "/upload");
      navigate(0);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "30px",
        backgroundColor: "#2C2C2C",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ color: "#FFFFFF" }}
      >
        Login
      </Typography>

      <Tooltip title="Enter your registered email address" arrow>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#B0B0B0" } }}
          InputProps={{ style: { color: "#FFFFFF" } }}
          sx={{
            "&:focus-within": { borderColor: "#4CAF50" },
            "&:hover": { borderColor: "#4CAF50" },
            borderColor: "#555",
            bgcolor: "#444",
          }}
        />
      </Tooltip>

      <Tooltip title="Enter your password" arrow>
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#B0B0B0" } }}
          InputProps={{ style: { color: "#FFFFFF" } }}
          sx={{
            "&:focus-within": { borderColor: "#4CAF50" },
            "&:hover": { borderColor: "#4CAF50" },
            borderColor: "#555",
            bgcolor: "#444",
          }}
        />
      </Tooltip>
      {/* links for forgot password and magic login */}
      <Box sx={{ textAlign: "center" }}>
        <Link
          to="/forgot-password"
          style={{ marginRight: "10px", color: "#B0B0B0" }}
        >
          Forgot Password?
        </Link>
        <Link to="/magic-login" style={{ color: "#B0B0B0" }}>
          Sign in without Password
        </Link>
      </Box>

      {error && (
        <Alert
          severity="error"
          sx={{
            marginTop: "20px",
            backgroundColor: "#FF4C4C",
            color: "#FFFFFF",
          }}
        >
          {error}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          marginTop: "20px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": { backgroundColor: "#45A049" },
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
      </Button>

      {/* register link */}
      <Box sx={{ marginTop: "15px", textAlign: "center" }}> 
        <Typography variant="body2" sx={{ color: "#B0B0B0" }}>
          Don't have an account?{" "}
          <Link to="/register" style={{ color: "#4CAF50" }}>
            Sign Up
          </Link>
        </Typography>
      </Box>

    </Box>
  );
};

export default AdminLogin;
