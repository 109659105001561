// components/ProtectedAdminRoute.js

import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

// Protect admin routes by checking if the user is an admin via the backend
const ProtectedAdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setIsAdmin(false);
      setLoading(false);
      return;
    }

    // Send a request to the backend to verify if the user is an admin
    const verifyAdmin = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/check-admin`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(response.data.isAdmin);
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    verifyAdmin();
  }, []);

  if (loading) return <div>Loading...</div>;

  if (!isAdmin) {
    return <Navigate to="/upload" />;
  }

  return children;
};

export default ProtectedAdminRoute;
