// components/MagicLoginCallback.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";

const MagicLoginCallback = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyLoginLink = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/password/magic-login/${token}`
        );
        const jwtToken = response.data.token;
        localStorage.setItem("token", jwtToken);
        navigate("/");
        navigate(0);
      } catch (error) {
        setError("Invalid or expired login link");
      } finally {
        setLoading(false);
      }
    };

    verifyLoginLink();
  }, [token, navigate]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>{error ? <Typography color="error">{error}</Typography> : null}</div>
  );
};

export default MagicLoginCallback;
